import React from "react";
import Link from "./Link";
import Image from "next/image";
import { filter } from "graphql-anywhere";
import EqualHousing from "lib/svg/equalHousing";
import { twMerge } from "tailwind-merge";
import { FooterOnCrmUserFragment } from "./gql";
import { getBrokerageLogoUrl } from "./utils";
import { formatPhone } from "@propertysimple/components";

function FooterRow({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const footerRowBaseClasses = "footer py-6 lg:py-12 text-base";
  return (
    <div className={twMerge(footerRowBaseClasses, className)}>{children}</div>
  );
}

function FooterTitle({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <h6
      className={twMerge("font-bold normal-case mb-2 text-lg", className)}
      style={{ filter: "brightness(1.2)" }}
    >
      {children}
    </h6>
  );
}

interface FooterProps {
  crmUser: {
    id: string;
    phone: string;
    email: string;
    twilio_phone_number: string;
    brokerage_address: string;
    brokerage_logo_url: string;
    brokerage_logo_from_xml: string;
    first_name: string;
    last_name: string;
    custom_website: {
      id: string;
      brokerage_name: string;
    };
  };
}

export default function Footer({ crmUser: _crmUser }: FooterProps) {
  const crmUser = filter(FooterOnCrmUserFragment, _crmUser);
  const customWebsite = crmUser.custom_website;

  return (
    <footer className="bg-base-200 text-base-content/90">
      <div className="container">
        <FooterRow>
          <div className="footer text-base lg:px-0 grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="grow flex flex-col gap-0">
              <div className="relative w-[150px] mb-2">
                {getBrokerageLogoUrl(crmUser) && (
                  <img
                    src={getBrokerageLogoUrl(crmUser)}
                    alt={`${customWebsite.brokerage_name} Logo`}
                    style={{
                      maxHeight: "3.125rem",
                      width: "auto",
                      maxWidth: "9.375rem",
                    }} // 50px and 150px
                  />
                )}

                {!getBrokerageLogoUrl(crmUser) && (
                  <Image
                    src="/images/logo-horizontal.svg"
                    alt="Property Simple logo"
                    className="w-full max-w-[6rem] h-full max-h-[2rem] block cursor-pointer"
                    width={75}
                    height={24}
                  />
                )}
              </div>
              <FooterTitle className="mb-0">
                {crmUser.first_name} {crmUser.last_name}, Realtor
              </FooterTitle>
              <p>{customWebsite.brokerage_name}</p>
              <p>{crmUser.brokerage_address}</p>
              <EqualHousing
                className="mt-2 h-7 w-7"
                style={{ fill: "oklch(var(--bc) / 0.8)" }}
              />
            </div>

            <div className="grow">
              <FooterTitle>Contact</FooterTitle>
              {crmUser.phone && <p>Phone: {formatPhone(crmUser.phone)}</p>}
              {crmUser.email && <p>Email: {crmUser.email}</p>}
              {crmUser.twilio_phone_number && (
                <p>
                  Call or Text 24/7 Support:{" "}
                  {formatPhone(crmUser.twilio_phone_number)}
                </p>
              )}
            </div>

            <div className="grow">
              <FooterTitle>Client Resources</FooterTitle>
              <Link href="/search" className="link link-hover">
                Home Search
              </Link>
              <Link href="/sell-your-home-with-me" className="link link-hover">
                Seller Info
              </Link>
              <Link href="/terms-and-conditions" className="link link-hover">
                Terms & Conditions
              </Link>
              <Link href="/privacy-policy" className="link link-hover">
                Privacy
              </Link>
            </div>
          </div>
        </FooterRow>

        <FooterRow className="footer-center w-full py-4 sm:py-8 px-4 border-t border-base-content/30">
          <p>Copyright © 2024 PropertySimple, Inc. All rights reserved.</p>
        </FooterRow>
      </div>
    </footer>
  );
}
