"use client";
import { getEnvParam } from "@propertysimple/components";
import { twMerge } from "tailwind-merge";
import { usePostHog } from "posthog-js/react";

interface ActivateWebsiteCtaButtonProps {
  className?: string;
  buttonText?: string;
}

export default function ActivateWebsiteCtaButton(
  props: ActivateWebsiteCtaButtonProps,
) {
  const posthog = usePostHog();
  const agentsSiteUrl = getEnvParam(process.env.APP_ENVIRONMENT, "base_url");
  const activateWebsiteUrl = `${agentsSiteUrl}/websites/get-realtor-website?show_modal=true`;

  return (
    <a
      href={activateWebsiteUrl}
      onClick={() => {
        posthog?.capture("activate_website_button_clicked");
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <button
        className={twMerge("px-4 py-1 bg-white text-black rounded font-bold", props.className)}
        type="button"
      >
        {props.buttonText || "See Pricing"}
        <span className="text-lg ml-2">👀</span>
      </button>
    </a>
  );
}
