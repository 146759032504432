export const PERFORMANCE_DEBUG = true;

export const MAINTENANCE_MODE = false;

const config = (key) => {
  console.log("key: ", key);
  console.log("value: ", process.env[key]);
  console.log("process.env: ", process.env);
  return process.env[key];
};

export default config;
