const Rollbar = require("rollbar");

const CLIENT_TOKEN = "e00d9b01b3394e40b88db4fac6168159";

const environment =
  process.env.APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT;

const baseConfig = {
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment,
  payload: {
    environment,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.GIT_HASH,
      },
    },
  },
};

const clientConfig = {
  accessToken: CLIENT_TOKEN,
  ...baseConfig,
};

clientConfig.payload.custom = {
  logger: "client logger",
};

const serverConfig = {
  ...baseConfig,
  accessToken:
    process.env.SSR_ROLLBAR_TOKEN || process.env.REACT_APP_SSR_ROLLBAR_TOKEN,
};

serverConfig.payload.custom = {
  logger: "server logger1",
};

const serverInstance = new Rollbar(serverConfig);

module.exports = {
  clientConfig,
  serverInstance,
};
