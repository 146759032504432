export function authenticatedUser() {
  try {
    return JSON.parse(window.localStorage.getItem("user") || "{}");
  } catch (error) {
    return null;
  }
}

// a better name
const getUserFromLocalStorage = authenticatedUser;

export function authenticatedUserID() {
  const authenticated = authenticatedUser();

  return authenticated?.id;
}

export { getUserFromLocalStorage };
