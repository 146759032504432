import { gql } from "@apollo/client";
import { HeaderOnCrmUserFragment } from "components/RealtorWebsite/Header";
import { FooterOnCrmUserFragment } from "components/RealtorWebsite/gql";

export const ERROR_PAGE_QUERY = gql`
  query getErrorPageData($domain_name: String!) {
    crmUserByDomainName(base_domain_name: $domain_name) {
      id
      first_name
      ...HeaderOnCrmUserFragment
      ...FooterOnCrmUserFragment
    }
  }
  ${HeaderOnCrmUserFragment}
  ${FooterOnCrmUserFragment}
`;
