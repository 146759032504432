export const MOBILE_BREAKPOINT = "768px";
export const DESKTOP_BREAKPOINT = "769px";
export const navbarHeight = "62px";
// Represents the maximum width for each size
export const responsiveSizes = {
  small: "400px",
  mobile: "576px",
  tablet: "768px",
  desktop: "992px",
  wide: "1200px",
};
export const responsiveSizesInPixels = {
  small: 400,
  mobile: 576,
  tablet: 768,
  desktop: 992,
  wide: 1200,
};
export const desktopBreakPointInPixels = 768;
export const tabletBreakPointInPixels = 576;

export const smallMediaQuery = "max-width: 399px";
export const mobileMediaQuery = "max-width: 767px";
export const tabletMediaQuery = "min-width: 768px";
export const desktopMediaQuery = "min-width: 992px";
export const wideMediaQuery = "min-width: 1201px";

export const exclusiveMobileMediaQuery = "(max-width: 767px)";
export const exclusiveTabletMediaQuery =
  "(min-width: 768px) and (max-width: 991px)";
export const exclusiveDesktopMediaQuery =
  "(min-width: 992px) and (max-width: 1199px)";
export const exclusiveWideMediaQuery = "(min-width: 1200px)";

export const colorPink = "#ff3867";
export const IS_DEVELOP = true;

export const joinArray = (array) =>
  array && Array.isArray(array) && array.join(", ");
export const miniaturesHeight = "200px";
export const listingPrimaryImageSize = "primaryListingImage";

export const listhubMetricsProviderID = "M-3230";
export const listhubIsTest = false;

export const orangeColorWithOpacity = (opacity = "1") =>
  `rgba(241, 90, 41, ${opacity})`;
export const yellowColorWithOpacity = (opacity = "1") =>
  `rgba(255, 203, 31, ${opacity})`;
export const purpleColorWithOpacity = (opacity = "1") =>
  `rgba(172, 0, 189, ${opacity})`;
export const blueColorWithOpacity = (opacity = "1") =>
  `rgba(69, 179, 222, ${opacity})`;
export const pinkColorWithOpacity = (opacity = "1") =>
  `rgba(255, 56, 103, ${opacity})`;
export const INTERCOM_APP_ID = "gng8hr8c";
