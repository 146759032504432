'use client';

import React from 'react';
import smsLink from 'sms-link';
import { twMerge } from 'tailwind-merge';

import { formatPhoneNumber } from 'lib/strings';

interface SmsLinkType {
  message: string;
  phoneNumber: string;
  style?: React.CSSProperties;
  className?: string;
  buttonText?: string;
}

export default function SendSmsButton(props: SmsLinkType) {
  const phoneNumber = formatPhoneNumber(props.phoneNumber || '');
  const text =
    props.buttonText || `Call or Text ${formatPhoneNumber(phoneNumber)}`;

  return (
    <button
      onClick={() =>
        window.open(smsLink({ phone: phoneNumber, body: props.message }))
      }
      style={props.style}
      className={twMerge("btn btn-primary font-bold", props.className)}
    >
      {text}
    </button>
  );
}
