import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 200px;
  text-align: center;
  button {
    height: 40px;
    color: #ff3678;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid #ff3678;
    background: transparent;
    font-size: 1rem;
    padding: 0 20px;
    margin-top: 20px;
  }
`;

export const Message = styled.div`
  background-color: rgba(241, 241, 241, 0.5);
  width: 40%;
  @media (max-width: 768px) {
    width: 80%;
  }
  text-align: center;
  margin: 0 auto;
  padding: 25px;
  & > div:first-child {
    font-size: 1.375rem;
    margin-bottom: 10px;
  }
`;

export const IconWrapper = styled.div`
  margin-bottom: 40px;
  svg {
    width: 300px;
  }
`;
