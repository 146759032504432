import posthog from "posthog-js";
import { Router } from "next/router";
import { getEnvParam } from "@propertysimple/components";

function handleRouteChange() {
  posthog?.capture("$pageview");
}

export function initPostHogForPagesRouter() {
  const env = process.env.APP_ENVIRONMENT;
  posthog.init(getEnvParam(env, "posthog_key"), {
    api_host: getEnvParam(env, "posthog_url"),
    loaded: (posthog) => {
      // Enable debug mode in development
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
  Router.events.on("routeChangeComplete", handleRouteChange);
}

export function stopPostHogForPagesRouter() {
  Router.events.off("routeChangeComplete", handleRouteChange);
}

export function postHogIdentifyCrmUser(crmUser: { id: string; email: string; name: string }) {
  console.log("calling postHogIdentifyCrmUser", crmUser);
  if (!crmUser?.id) return;
  posthog.identify(crmUser.id, {
    email: crmUser.email,
    name: crmUser.name,
  });
}