import { gql } from "@apollo/client";

const AuthUserFragment = gql`
  fragment AuthUserFragment on User {
    id
    firstName: first_name
    name
    user_name
    homebuyer {
      id
    }
    crm_user {
      id
      business_card_photo
      avatar_centered
    }
  }
`;
export default AuthUserFragment;
