export const unescapeHTML = (html = ""): string => html.replace(/&apos;/g, "'");

export function formatPhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace(/\+1(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}

export function formatNumberAsPhoneNumber(number: string): string {
  return number
    ? number
        .replace(/\D/g, "")
        .split("")
        .reduce((acc, digit, i) => {
          if (i === 0) return "(" + digit;
          if (i === 3) return acc + ") " + digit;
          if (i === 6) return acc + "-" + digit;
          if (i >= 10) return acc;

          return acc + digit;
        }, "")
    : "";
}

export function truncateToNLines(
  text: string,
  numLines: number,
  target: React.RefObject<HTMLDivElement>,
) {
  if (!target.current) return text;

  // Create a hidden div to measure text
  const measureDiv = document.createElement("div");

  try {
    // Copy styles from target element for accurate measurement
    const targetStyles = window.getComputedStyle(target.current);
    measureDiv.style.cssText = `
      visibility: hidden;
      position: absolute;
      width: ${targetStyles.width};
      font-size: ${targetStyles.fontSize};
      font-family: ${targetStyles.fontFamily};
      font-weight: ${targetStyles.fontWeight};
      line-height: ${targetStyles.lineHeight};
      padding: ${targetStyles.padding};
    `;
    target.current.appendChild(measureDiv);

    // Add text and measure height of single line
    measureDiv.textContent = "M";
    const lineHeight = measureDiv.offsetHeight;
    const targetHeight = lineHeight * numLines;

    // Add full text
    measureDiv.textContent = text;

    // If text already fits, return it as-is
    if (measureDiv.offsetHeight <= targetHeight) {
      return text;
    }

    // Binary search to find optimal length
    let start = 0;
    let end = text.length;
    let bestLength = end;

    while (start <= end) {
      const mid = Math.floor((start + end) / 2);

      measureDiv.textContent = text.slice(0, mid) + "...";

      if (measureDiv.offsetHeight <= targetHeight) {
        bestLength = mid;
        start = mid + 1;
      } else {
        end = mid - 1;
      }
    }

    return text.slice(0, bestLength) + "...";
  } finally {
    // Always clean up
    target.current?.contains(measureDiv) &&
      target.current.removeChild(measureDiv);
  }
}
