"use client";

import React, { useState, useRef } from "react";
import { usePathname } from "next/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { twMerge } from "tailwind-merge";
import { useOnClickOutside } from "usehooks-ts";

import Link from "components/RealtorWebsite/Link";

type MobileHeaderNavProps = {
  className?: string;
};

const SELL_YOUR_HOME_WITH_ME_PATHNAME = "/sell-your-home-with-me";

const NAV_ITEMS = [
  { label: "Home Page", href: "/" },
  { label: "Buy or Rent", href: "/search" },
  { label: "Sell Your Home With Me", href: SELL_YOUR_HOME_WITH_ME_PATHNAME },
];

export default function MobileHeaderNav({ className }: MobileHeaderNavProps) {
  const ref = useRef(null);
  const pathname = usePathname() || "";
  const [isOpen, setIsOpen] = useState(false);
  const items = NAV_ITEMS.filter((item) => item.href !== pathname);

  useOnClickOutside(ref, () => setIsOpen(false));

  const handleToggle = (evt) => {
    evt.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const handleLinkClick = (evt) => {
    evt.stopPropagation();
    setIsOpen(false);
  };

  return (
    <div
      className={twMerge("relative", className)}
      ref={ref}
      onClick={(evt) => evt.stopPropagation()}
    >
      <button
        type="button"
        onClick={handleToggle}
        className="flex items-center btn"
      >
        <FontAwesomeIcon className="w-4 h-4" icon={faBars} />
      </button>

      {isOpen && (
        <ul
          className={twMerge(
            `absolute mt-2 bg-base-100 z-[1000]
                              shadow-xl text-base
                              border border-base-200
                              right-0 top-full`,
            pathname === SELL_YOUR_HOME_WITH_ME_PATHNAME
              ? "w-[168px]"
              : "w-[212px]",
          )}
        >
          {items.map((item) => (
            <li key={item.href}>
              <Link
                href={item.href}
                className={`flex justify-start items-center h-[3rem]
                            p-2 pl-4 hover:bg-neutral-200`}
                onClick={handleLinkClick}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
