import React, { useEffect } from "react";
import { getUserFromLocalStorage } from "lib/user";
import { useRouter } from "next/router";

function getGaUserId() {
  const user = getUserFromLocalStorage();
  const userId = user?.id;
  if (!userId) return undefined;
  return `user-id-${userId}`;
}

function getGaParams() {
  const gaUserId = getGaUserId();
  const params = { page_location: location.href };
  if (gaUserId) {
    params.user_id = gaUserId;
  }
  return params;
}

function submitGtagParams(params) {
  const { gtag } = window;
  if (process.env.googleAdsConversionId) {
    gtag("config", process.env.googleAdsConversionId, params);
  }
  if (process.env.googleAnalyticsMeasurementId) {
    gtag("config", process.env.googleAnalyticsMeasurementId, params);
  }
}

function logGaPageView() {
  const { gtag } = window;
  if (!gtag) return;
  const params = getGaParams();
  submitGtagParams(params);
}

export function logGaUserId() {
  const { gtag } = window;
  if (!gtag) return;
  const gaUserId = getGaUserId();
  if (!gaUserId) return;
  submitGtagParams({ user_id: gaUserId });
}

export default function PageViewTracker() {
  const { pathname } = useRouter();

  useEffect(
    function () {
      if (window.fbq) {
        let fbPixelId = process.env.portalFbPixelId;
        if (
          pathname ===
          "/search/[state]/[city]/[category]/[address_slug]/[listing_id]"
        ) {
          fbPixelId = process.env.homebuyerFbPixelId;
        }
        window.fbq("trackSingle", fbPixelId, "PageView");
      }
      logGaPageView();
    },
    [pathname],
  );

  return null;
}
