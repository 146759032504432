import React from "react";
import App from "next/app";
import Head from "next/head";
import { ApolloProvider } from "@apollo/client";
import { initListhub } from "lib/listhub";
import apolloClient from "lib/apolloClient";
import PageViewTracker from "components/PageViewTracker";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { clientConfig as rollbarClientConfig } from "lib/rollbar";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";
import {
  initPostHogForPagesRouter,
  stopPostHogForPagesRouter,
} from "lib/posthog/utils";
import AuthenticatedUser from "components/AuthenticatedUser";
import { MAINTENANCE_MODE } from "lib/config";
import { MaintenancePage } from "@propertysimple/components";
import ErrorPage from "components/ErrorPage";
import "components/Search/Map/marker.css";
import "components/Search/Map/style.css";
import "rc-slider/assets/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "../styles.css";
import "@propertysimple/styles/styles.css";

// From https://fontawesome.com/docs/web/use-with/react/use-with#getting-font-awesome-css-to-work
config.autoAddCss = false;

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps };
  }

  setVHCSSUnit() {
    var vh = window.innerHeight * 0.01;
    console.debug(`Setting --vh css variable to ${vh}px`);
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  setupVHCssUnit() {
    try {
      this.setVHCSSUnit();
      window.addEventListener("resize", () => {
        this.setVHCSSUnit();
      });
    } catch (err) {
      console.error("Error while setting up --vh css variable:", err);
    }
  }

  componentDidMount() {
    this.setupVHCssUnit();
    initListhub();
    initPostHogForPagesRouter();
  }

  componentWillUnmount() {
    stopPostHogForPagesRouter();
  }

  render() {
    const { Component, pageProps } = this.props;

    if (MAINTENANCE_MODE) return <MaintenancePage />;

    return (
      <React.Fragment>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover"
          />
        </Head>
        <RollbarProvider config={rollbarClientConfig}>
          <ErrorBoundary level="error" fallbackUI={ErrorPage}>
            <ApolloProvider client={apolloClient}>
              <AuthenticatedUser>
                {typeof window !== "undefined" && <PageViewTracker />}
                <PostHogProvider client={posthog}>
                  <Component {...pageProps} />
                </PostHogProvider>
              </AuthenticatedUser>
            </ApolloProvider>
          </ErrorBoundary>
        </RollbarProvider>
      </React.Fragment>
    );
  }
}

export default MyApp;
