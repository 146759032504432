/**
 * copies login user data value by value
 * and writes the stringified JSON to the localStorage
 */
export function writeUserToLocalStorage(data) {
  const cleanedData = {
    id: data.id,
    avatar: data.avatar,
    facebook_profile_url: data.facebook_profile_url,
    jwt: data.jwt,
    name: data.name, // full name
    user_name: data.user_name, // slugified name
  };
  if (data.crm_user) {
    cleanedData.crm_user = {
      id: data.crm_user.id,
      business_card_photo: data.crm_user.business_card_photo,
    };
  }
  if (data.homebuyer) {
    cleanedData.homebuyer = { id: data.homebuyer.id };
  }
  window.localStorage.setItem("user", JSON.stringify(cleanedData));
}
