import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { gql } from "@apollo/client";
//import { getUserFromLocalStorage } from 'lib/user'
//import useCrmUser from 'lib/hooks/useCrmUser'
import {
  getEnvParam,
  SaturdayCountdownTimer,
} from "@propertysimple/components";

const BREAK = "840px";
const TEXT_COLOR = "#000000";
const BUTTON_TEXT_COLOR = "white";
const COUNTDOWN_NUMBER_COLOR = "white";
const BUTTON_BACKGROUND = "linear-gradient(99deg, #e8287f, #bb0aae 99%)";
const BANNER_BACKGROUND = "#FBDFFE";
const BUTTON_TEXT = "Get My Discount";
const BANNER_TEXT = "Valentine’s Day Sale - 86% OFF!";

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.55rem 1rem 0.7rem 1rem;
  width: 100%;
  color: white;
  background: ${BANNER_BACKGROUND};

  @media (max-width: ${BREAK}) {
    flex-direction: column;
  }
`;

const BlackFridayCta = styled.div`
  display: flex;
  align-items: center;
  background: transparent;
  flex-wrap: wrap;

  @media (max-width: ${BREAK}) {
    flex-direction: column;
  }

  & > * {
    margin-bottom: 0;
    margin-right: 0.5rem;

    @media (max-width: ${BREAK}) {
      margin-bottom: 0.5rem;
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
`;

export const BlackFridayBannerOnCrmUserFragment = gql`
  fragment BlackFridayBannerOnCrmUserFragment on CrmUser {
    id
    paid_tier
    zips {
      id
    }
  }
`;

const DISABLE_BANNER = true;

export default function BlackFridayBanner({ className, style }) {
  const [isClient, setIsClient] = useState(false);
  //const crmUser = useCrmUser(BlackFridayBannerOnCrmUserFragment)
  //const isAuthenticated = !!getUserFromLocalStorage()?.id

  //const userTier = crmUser?.paid_tier
  //const freeEnough = ['free', 'subscribed_to_ads'].includes(userTier)
  //const isPaid = isAuthenticated && !!userTier && !freeEnough
  //const isUnknown = isAuthenticated && !userTier //still loading user tier

  useEffect(() => setIsClient(true), []);

  if (!isClient || DISABLE_BANNER) {
    return null;
  }

  //if (isPaid || isUnknown) {
  //return null
  //}

  function getTargetUrl() {
    const agentsSiteUrl = getEnvParam(process.env.APP_ENVIRONMENT, "base_url");
    return `${agentsSiteUrl}/pricing?plan=automation&level=monthly`;
  }

  return (
    <Wrapper {...{ className, style }}>
      <BlackFridayCta>
        <SaturdayCountdownTimer
          hideLabel
          hideFireworks
          noPadding
          endsAt="wednesday"
          style={{
            color: "#000000",
            background: "transparent",
            marginRight: "0.5rem",
          }}
          boxStyle={{
            background: BUTTON_BACKGROUND,
            color: COUNTDOWN_NUMBER_COLOR,
            fontWeight: "bold",
          }}
        />
        <div
          style={{
            color: TEXT_COLOR,
            background: "transparent",
            fontWeight: "bold",
          }}
        >
          {BANNER_TEXT}
        </div>
        <button
          className="btn btn-compact nowrap"
          style={{
            background: BUTTON_BACKGROUND,
            color: BUTTON_TEXT_COLOR,
            border: "none",
          }}
          onClick={() => {
            window.location.href = getTargetUrl();
          }}
        >
          {BUTTON_TEXT}
        </button>
      </BlackFridayCta>
    </Wrapper>
  );
}
