"use client";

import React, { useEffect, useState } from "react";

import PropertySimpleErrorPage from "components/PropertySimpleErrorPage";
import RealtorErrorPage from "components/RealtorErrorPage";

export default function ErrorPage() {
  const [hostname, setHostname] = useState("");

  useEffect(() => {
    setHostname(window.location.hostname);
  }, []);

  // render
  const isPropertySimpleWebsite = hostname.includes("propertysimple.com");

  return (
    <>
      {isPropertySimpleWebsite ? (
        <PropertySimpleErrorPage />
      ) : (
        <RealtorErrorPage />
      )}
    </>
  );
}
