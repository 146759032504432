import { listhubMetricsProviderID, listhubIsTest } from "lib/constants";

export function initListhub() {
  // From: https://app.listhub.com/tracking-docs/v3/listing-metrics-js.html#include-javascript
  (function (l, i, s, t, h, u, b) {
    l.ListHubAnalyticsObject = h;
    (l[h] =
      l[h] ||
      function () {
        (l[h].q = l[h].q || []).push(arguments);
      }),
      (l[h].d = 1 * new Date());
    (u = i.createElement(s)), (b = i.getElementsByTagName(s)[0]);
    u.async = 1;
    u.src = t;
    b.parentNode.insertBefore(u, b);
  })(window, document, "script", "//tracking.listhub.net/la.min.js", "lh");

  if (window.lh) {
    window.lh("init", {
      provider: listhubMetricsProviderID,
      test: listhubIsTest,
    });
  }
}
