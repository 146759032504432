import React from "react";
import Roof from "lib/svg/coloredRoof";
import dynamic from "next/dynamic";

const Navbar = dynamic(() => import("components/Navbar/New"));

import { Wrapper, IconWrapper, Message } from "./styles";

export default function PropertySimpleErrorPage() {
  return (
    <>
      <Navbar origin="error-page" />
      <Wrapper>
        <IconWrapper>
          <Roof />
        </IconWrapper>
        <Message>
          <div>Oops! Not under this roof.</div>
          <div>We can&apos;t find the page you&apos;re looking for.</div>
        </Message>
      </Wrapper>
    </>
  );
}
