import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import AuthenticatedUserContext from "lib/context/AuthenticatedUserContext";
import AuthenticatedCrmUserContext from "lib/context/AuthenticatedCrmUserContext";
import { authenticatedUser } from "lib/user";
import { writeUserToLocalStorage } from "./utilities";
import { Query } from "@apollo/client/react/components";
import AuthUserFragment from "components/AuthenticatedUser/gql/AuthUserFragment";
import { usePolicyUserFragment } from "@propertysimple/components";
import NavbarOnUserFragment from "components/Navbar/NavbarOnUserFragment";
import { AdvertiseListingLinkOnCrmUserFragment } from "components/Listing/AdvertiseListingLink";
import { BlackFridayBannerOnCrmUserFragment } from "components/BlackFridayBanner";

export const authenticatedUserQuery = gql`
  ${usePolicyUserFragment}
  ${AuthUserFragment}
  ${NavbarOnUserFragment}
  ${AdvertiseListingLinkOnCrmUserFragment}
  ${BlackFridayBannerOnCrmUserFragment}

  query authenticatedUserQuery($id: ID) {
    user(id: $id) {
      id
      ...usePolicyUserFragment

      crm_user {
        id
        ...AdvertiseListingLinkOnCrmUserFragment
        ...BlackFridayBannerOnCrmUserFragment
      }

      ...AuthUserFragment
      ...NavbarOnUserFragment
    }
  }
`;

export default function AuthenticatedUser({ children }) {
  const [userFromLocalStorage, setUserFromLocalStorage] = useState(null);
  const [
    waitingToReadUserFromLocalStorage,
    setWaitingToReadUserFromLocalStorage,
  ] = useState(true);
  const [userId, setUserId] = useState(userFromLocalStorage?.id);
  const [userFromQuery, setUserFromQuery] = useState(null);

  useEffect(() => {
    setWaitingToReadUserFromLocalStorage(false);
    const user = authenticatedUser();
    if (user) {
      setUserFromLocalStorage(user);
      setUserId(user.id);
    }
  }, []);

  function login(id, jwt) {
    /** writeUserToLocalStorage()
     * is needed here for the autherization
     * of the apollo queries and mutations
     */
    writeUserToLocalStorage({ id, jwt }); // see comment above
    setUserId(id);
  }

  function logout() {
    window.localStorage.removeItem("user");
    setUserFromLocalStorage(null);
    setUserFromQuery(null);
    setUserId(null);
  }

  function handleQueryCompleted(data) {
    const user = data?.user;
    if (user) {
      setUserFromQuery(user);
      const jwt = authenticatedUser()?.jwt;
      const userWithToken = Object.assign({}, user, { jwt });
      writeUserToLocalStorage(userWithToken);
      setUserFromLocalStorage(userWithToken);
    }
  }

  return (
    <Query
      query={authenticatedUserQuery}
      variables={{ id: userId }}
      skip={!userId}
      ssr={false}
      onCompleted={handleQueryCompleted}
    >
      {({ loading }) => {
        const value = {
          userIsAuthenticated: !!userId,
          waitingToReadUserFromLocalStorage,
          loadingUser: loading,
          logout,
          login,
          userFromLocalStorage,
          userFromQuery,
        };

        return (
          <AuthenticatedUserContext.Provider value={value}>
            <AuthenticatedCrmUserContext.Provider
              value={value?.userFromQuery?.crm_user}
            >
              {children}
            </AuthenticatedCrmUserContext.Provider>
          </AuthenticatedUserContext.Provider>
        );
      }}
    </Query>
  );
}
