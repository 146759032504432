'use client';

import Link from "../Link";
import { twMerge } from "tailwind-merge";
import { usePathname } from "next/navigation";

export default function DesktopHeaderNav(props: {className?: string}) {
  const pathname = usePathname() || '';
  const activeClass = 'bg-neutral-400 rounded-md';

  return (
    <ul className={twMerge("menu menu-horizontal flex-row gap-2", props.className)}>
      <li className={['/', '/preview'].includes(pathname) ? activeClass : ''}>
        <Link href='/'>Home</Link>
      </li>
      <li className={pathname?.includes('/search') ? activeClass : ''}>
        <Link href='/search'>Buy or Rent</Link>
      </li>
      <li className={pathname?.includes('/sell-your-home-with-me') ? activeClass : ''}>
        <Link href='/sell-your-home-with-me'>Sell Your Home With Me</Link>
      </li>
    </ul>
  );
}
