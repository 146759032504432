import React from "react";

export default function ColoredRoof() {
  return (
    <svg viewBox="0 0 232 161.852">
      <path
        fill="none"
        d="M120 133.85h16v16h-16zm-24 0h16v16H96zm24-24h16v16h-16zm-24 0h16v16H96z"
      />
      <path
        fill="#EB4548"
        d="M232 153.85h-43.358l9.194-38.61a6 6 0 0 0-.93-4.844l-76-108c-2.246-3.195-7.566-3.195-9.812 0l-76 108a6 6 0 0 0-.93 4.844l9.194 38.61H0v8h56v-8h-.308l-9.23-38.758L116 16.275l69.537 98.817-9.229 38.758H176v8h5.986l.02.002.014-.002H232v-8z"
      />
      <path
        fill="#FE8657"
        d="M88 157.85h56v-56H88v56zm8-24h16v16H96v-16zm24 16v-16h16v16h-16zm16-40v16h-16v-16h16zm-24 0v16H96v-16h16z"
      />
    </svg>
  );
}
