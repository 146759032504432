"use client";

import { default as NextLink } from "next/link";
import { useSearchParams } from "next/navigation";
import { usePathname } from "next/navigation";

const getDomainName = (href: string): string => {
  try {
    const matches = href.match(
      /^(?:https?:\/\/)?(?:www\.)?([^/?#]+)(?:[/?#]|$)/i,
    );
    const domain = matches && matches[1];

    return domain || "";
  } catch (error) {
    return "";
  }
};

const generateQueryParams = (href: string, crmUserId: string) => {
  const queryParams = href.split("?")[1];
  const searchParams = new URLSearchParams(queryParams);
  searchParams.set("crm_user_id", crmUserId);

  return searchParams.toString();
};

export default function Link(props: {
  href: string;
  children: React.ReactNode;
  className?: string;
  onClick?: (...args: any[]) => void;
}) {
  const hrefDomain = getDomainName(props.href);

  if (hrefDomain) {
    return (
      <NextLink
        href={props.href}
        className={props.className}
        onClick={props.onClick}
      >
        {props.children}
      </NextLink>
    );
  } else {
    const searchParams = useSearchParams();
    const pathname = usePathname();
    const crmUserId = searchParams?.get("crm_user_id") || "";
    const isPreview = pathname?.startsWith("/preview");
    const queryParams = generateQueryParams(props.href, crmUserId);
    const hrefWithoutQueryParams = props.href.split("?")[0];
    let href = isPreview
      ? `/preview${hrefWithoutQueryParams}?${queryParams}`
      : props.href;

    // if the href is a hash we want to keep the default behavior of the Next.js Link component
    if (props.href === "#") {
      href = "#";
    }

    return (
      <NextLink href={href} className={props.className} onClick={props.onClick}>
        {props.children}
      </NextLink>
    );
  }
}
