import { gql } from "@apollo/client";

const NavbarOnUserFragment = gql`
  fragment NavbarOnUserFragment on User {
    id
    avatar
    email
    name
    first_name
    user_name
    crm_user {
      id
      business_card_photo
      avatar_centered
    }
  }
`;

export default NavbarOnUserFragment;
