import { ACTIVE_NAV_CLASS } from "./constants";

export function clearActiveHeaderNav() {
  const links = document.querySelectorAll(
    ".header-search-link, .header-sell-your-home-with-me-link, .header-questions-link",
  );
  links.forEach((link) => {
    link.classList.remove(...ACTIVE_NAV_CLASS);
  });
}

/** 
 * decides which brokerage logo to use
 * there is a fragment for this in the gql file
 */
export function getBrokerageLogoUrl(crmUser: any) {
  return crmUser.brokerage_logo_url || crmUser.brokerage_logo_from_xml || 'https://property-simple-dev-images.s3.us-west-2.amazonaws.com/your_logo_here_v2.png';
}
