"use client";

import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import client from "lib/apolloClient";
import Header from "components/RealtorWebsite/Header";
import Footer from "components/RealtorWebsite/Footer";
import { useRollbar } from "@rollbar/react";

import "pages/globals.css";

import { ERROR_PAGE_QUERY } from "./gql";

export default function RealtorErrorPage() {
  const rollbar = useRollbar();
  const [crmUser, setCrmUser] = useState<any>(null);
  const [showClaimWebsiteButton, setShowClaimWebsiteButton] =
    useState<boolean>(false);

  useEffect(() => {
    setShowClaimWebsiteButton(
      new URL(window.location.href).searchParams.get("claim_website") ===
        "true",
    );
  }, []);

  useEffect(() => {
    const getUser = async () => {
      const domain = window.location.hostname.split(":")[0];
      const variables = { domain_name: domain };
      try {
        const { data } = await client.query({
          query: ERROR_PAGE_QUERY,
          variables,
        });
        const user = data?.crmUserByDomainName;
        setCrmUser(user);
      } catch (error) {
        rollbar.error("Error loading user data by domain in error page", {
          domain,
          error,
        });
      }
    };

    getUser();
  }, []);

  // render
  return (
    <>
      {crmUser && (
        <Header
          crmUser={crmUser}
          showClaimWebsiteButton={showClaimWebsiteButton}
        />
      )}

      <div className="container p-12 md:p-36 flex flex-row justify-between items-center gap-12">
        <div>
          <hgroup className="mb-12">
            <h1 className="text-5xl font-bold mb-4">Oops!</h1>
            <h2 className="text-2xl">
              We can't seem to find the page you're looking for.
            </h2>
          </hgroup>

          <Link
            href="/"
            className="btn btn-primary text-white font-bold w-auto !pt-0"
          >
            {crmUser ? (
              <>Back to {crmUser.first_name}'s Website</>
            ) : (
              "Back to the Home Page"
            )}
          </Link>
        </div>

        <Image
          className="hidden md:block"
          src="/images/magnifying-glass.png"
          alt="404"
          width={225}
          height={225}
        />
      </div>

      {crmUser && <Footer crmUser={crmUser} />}
    </>
  );
}
