import React from "react";
import Image from "next/image";
import { gql } from "@apollo/client";
import { filter } from "graphql-anywhere";
import { twMerge } from "tailwind-merge";

import SendSmsButton from "components/RealtorWebsite/components/SendSmsButton";
import Link from "../Link";
import DesktopHeaderNav from "./DesktopHeaderNav";
import MobileHeaderNav from "./MobileHeaderNav";
import { getBrokerageLogoUrl } from "../utils";
import { GetBrokerageLogoUrlOnCrmUserFragment } from "../gql";
import ActivateWebsiteCtaButton from "components/RealtorWebsite/components/ActivateWebsiteCtaButton";

export const HeaderOnCrmUserFragment = gql`
  fragment HeaderOnCrmUserFragment on CrmUser {
    id
    name
    twilio_phone_number
    ...GetBrokerageLogoUrlOnCrmUserFragment
    custom_website {
      id
      brokerage_name
    }
  }
  ${GetBrokerageLogoUrlOnCrmUserFragment}
`;

interface HeaderProps {
  crmUser: {
    id: number;
    name: string;
    twilio_phone_number: string;
    brokerage_logo_url: string;
    brokerage_logo_from_xml: string;
    custom_website: {
      id: number;
      brokerage_name: string;
    };
  };
  fullWidth?: boolean;
  showClaimWebsiteButton?: boolean;
}

export default function Header({
  crmUser: _crmUser,
  fullWidth,
  showClaimWebsiteButton,
}: HeaderProps) {
  const crmUser = filter(HeaderOnCrmUserFragment, _crmUser);
  const twilioPhoneNumber = crmUser.twilio_phone_number;

  return (
    <React.Fragment>
      <header
        className={twMerge(
          "py-2 shadow-md",
          fullWidth ? "px-4" : "px-4 xxs:px-0 sm:px-4 md:px-4",
        )}
      >
        <div
          className={twMerge(
            "w-full grid grid-cols-12 p-0",
            !fullWidth ? "container" : "",
          )}
        >
          <div className="relative col-span-3 grid items-center">
            <Link href="/" className="flex items-center">
              {getBrokerageLogoUrl(crmUser) && (
                <img
                  src={getBrokerageLogoUrl(crmUser)}
                  alt={`${crmUser.custom_website.brokerage_name} Logo`}
                  style={{
                    maxHeight: "3.125rem",
                    width: "auto",
                    maxWidth: "9.375rem",
                  }} // 50px and 150px
                  className="mr-2"
                />
              )}

              {!getBrokerageLogoUrl(crmUser) && (
                <Image
                  src="/images/logo-horizontal.svg"
                  alt="Property Simple logo"
                  className="w-full max-w-[8rem] h-full max-h-[2rem] block cursor-pointer"
                  width={75}
                  height={24}
                />
              )}
            </Link>
          </div>

          <nav className="flex justify-end lg:justify-center sm:text-right col-span-9 lg:col-span-6">
            <DesktopHeaderNav className="hidden md:flex" />
            <MobileHeaderNav className="md:hidden" />
          </nav>

          <div className="hidden lg:block col-span-3 justify-self-end self-center">
            {showClaimWebsiteButton ? (
              <ActivateWebsiteCtaButton className="bg-primary text-primary-content" />
            ) : (
              <SendSmsButton
                message={`Hi ${crmUser.name}, I'm interested in buying or selling a home and wanted to connect to explore my options. When's a good time to chat?`}
                phoneNumber={twilioPhoneNumber}
              />
            )}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}
