import React from "react";
import styled from "styled-components";
import { gql } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/pro-light-svg-icons/faBullhorn";
import { getEnvParam } from "@propertysimple/components";
import useCrmUser from "lib/hooks/useCrmUser";

const Wrapper = styled.button`
  white-space: nowrap;
  svg {
    margin-right: 0.3625rem;
  }
`;

/** todo: add subscribed_to_ads_plus attribute */
export const AdvertiseListingLinkOnCrmUserFragment = gql`
  fragment AdvertiseListingLinkOnCrmUserFragment on CrmUser {
    id
    is_subscribed_to_ads_plus
  }
`;

function getListingAdUrl(listingId) {
  const agentsSiteURL = getEnvParam(process.env.APP_ENVIRONMENT, "base_url");
  return `${agentsSiteURL}/ads/purchase/listing/${listingId}/ntm`;
}

export default function AdvertiseListingLink({ listingId, className, style }) {
  const crmUser = useCrmUser(AdvertiseListingLinkOnCrmUserFragment);
  //const crmUser = useCrmUser(ADVERTISE_LISTING_LINK_ON_CRM_USER_FRAGMENT)
  //if (!crmUser?.subscribed_to_ads_plus) return null
  if (!crmUser?.id) return null;
  if (!crmUser?.is_subscribed_to_ads_plus) return null;

  function handleClick() {
    window.location.href = getListingAdUrl(listingId);
  }

  return (
    <Wrapper
      {...{ style, className }}
      className="btn btn-small btn-compact btn-gradient"
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faBullhorn} />
      Advertise
    </Wrapper>
  );
}
