import { useContext } from "react";
import { filter } from "graphql-anywhere";
import AuthenticatedCrmUserContext from "lib/context/AuthenticatedCrmUserContext";

function useCrmUser(fragment) {
  const currentCrmUser = useContext(AuthenticatedCrmUserContext);

  if (fragment) {
    return filter(fragment, currentCrmUser || {});
  }

  return currentCrmUser;
}

export default useCrmUser;
