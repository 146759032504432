import { gql } from "@apollo/client";

export const FooterOnCrmUserFragment = gql`
  fragment FooterOnCrmUserFragment on CrmUser {
    id
    phone
    email
    twilio_phone_number
    brokerage_address
    brokerage_logo_url
    brokerage_logo_from_xml
    first_name
    last_name
    custom_website {
      id
      brokerage_name
    }
  }
`;

export const LayoutOnCrmUserFragment = gql`
  fragment LayoutOnCrmUserFragment on CrmUser {
    id
    has_paid_custom_website
    first_name
    custom_website {
      id
      theme
    }
  }
`;

export const GetBrokerageLogoUrlOnCrmUserFragment = gql`
  fragment GetBrokerageLogoUrlOnCrmUserFragment on CrmUser {
    id
    brokerage_logo_url
    brokerage_logo_from_xml
  }
`;
