import {
  from,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from "@apollo/client";

const httpLink = new HttpLink({ uri: process.env.graphqlUrl });

export function createClient({ headers } = {}) {
  const userAgent = headers?.["user-agent"];
  const authMiddleware = new ApolloLink((operation, forward) => {
    let token = null;
    if (typeof window !== "undefined" && window.localStorage) {
      const user =
        window.localStorage.getItem("user") &&
        JSON.parse(window.localStorage.getItem("user"));
      token = user?.jwt || user?.token;
    }

    operation.setContext({
      headers: {
        "user-agent": userAgent,
        authorization: token || "",
      },
    });

    return forward(operation);
  });

  const client = new ApolloClient({
    ssr: true,
    cache: new InMemoryCache(),
    link: from([authMiddleware, httpLink]),
    uri: process.env.graphqlUrl,
  });

  return client;
}

const client = createClient();

export default client;
